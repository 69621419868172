import { render, staticRenderFns } from "./Menu2_Cpl.vue?vue&type=template&id=657ac8b9&scoped=true&"
import script from "./Menu2_Cpl.vue?vue&type=script&lang=js&"
export * from "./Menu2_Cpl.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "657ac8b9",
  null
  
)

export default component.exports